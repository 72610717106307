.bottom-mini-menu {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #1F1F1F;
    color: #fff;
    border-radius: 12px 12px 0 0;
    transform: translateY(100%);
    transition: transform 0.3s ease-in-out;
    z-index: 1009;
  
    &--visible {
      transform: translateY(0);
    }
  
    &__content {
      max-height: 60vh;
      overflow-y: auto;
    }
  
    &__title {
      display: flex;
      align-items: center;
      font-size: 16px;
      background-color: #3b3b3b;
      padding: 20px;
      border-radius: 12px 12px 0 0;
  
      img {
        width: 20px;
        margin-right: 10px;
      }
    }
  
    &__item {
      display: flex;
      align-items: center;
      padding: 20px;
      border-bottom: 1px solid #3b3b3b;
      color: #25E82A;
      text-decoration: none;
      cursor: pointer;
  
      .icon {
        width: 25px;
        margin-left: 10px;
        margin-right: 20px;
        padding: 5px;
        border-radius: 5px;
        background-color: #3b3b3b;
      }

      .icon_two {
        width: 31px;
        margin-left: 10px;
        margin-right: 20px;
        border-radius: 5px;
      }
  
      span {
        font-size: 14px;
      }
    }
  }
  
  @media (min-width: 1081px) {
    .bottom-mini-menu {
      display: none;
    }
  }
