.mini-menu {
    position: fixed;
    right: 0;
    top: 22%;
    z-index: 1009;
    background-color: transparent;
    color: #FFFFFF;
    cursor: pointer;

    &__outer {
        display: inline-block;
        width: 25px;
        padding: 10px;
        height: 150px;
        background-color: #000;
        border-radius: 6px 0px 0px 6px;
        position: relative;
        top: -18px;

        img {
            position: absolute;
            left: 10px;
            top: 10px;
            width: 20px;
            height: 20px;
        }
    }

    &__inner {
        display: none;
        width: 230px;
        font-size: 12px;
        background-color: #000;
        position: relative;
        border-radius: 0px 0px 0px 12px;

        .hoverGreen:hover {
            color: #25E82A;
        }

        ul {
            display: flex;
            flex-direction: column;
            list-style: none;

            &__item {
                display: flex;
                flex-direction: row;
                align-items: center;
            }

            li {
                flex: 1;
                height: 20px;
                padding: 10px;
                border-bottom: 1px solid #3B3B3B;

                .icon {
                    width: 25px;
                    margin: 5px 10px 5px 5px;
                    border-radius: 4px;
                    padding: 4px;
                    background-color: #3B3B3B;
                }

                .icon_two {
                    width: 30px;
                    margin: 5px 10px 5px 5px;
                    border-radius: 4px;
                }

                .showQR {
                    display: none;
                    flex: 4;
                    border-bottom: none;
                    background-size: 100%;
                    background-repeat: no-repeat;
                    background-position: center 56%;
                    border-radius: 0px 0px 0px 12px;
                    text-align: center;    

                    &-content {
                        margin: 10px auto;
                        border-radius: 4px;
                        width: 145px;
                        height: 200px;
                        text-align: center;
                        line-height: 22px;
                    }

                    &-text {
                        color: #25E82A;
                    }
                                   
                }

                // hot_line
                .hot_line {
                    display: flex;
                    flex-direction: column;
                }

                // telegram
                .telegram {
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;
                }
            }
        }
    }

    &__rotate {
        transform: rotate(-90deg);
        display: block;
        overflow: visible;
        word-wrap: normal;
        white-space: nowrap;
        position: relative;
        top: 90px;
        left: -5px;
    }

    a {
        color: #FFFFFF;
        text-decoration: none;
    }
}

.mini-menu__inner ul .mini-menu__item {
    display: flex;
    align-items: center; 
}



.mini-menu:hover {
    .mini-menu__inner {
        display: inline-block;
        animation: open 1s;
        animation-timing-function: ease;

        ul {
            li:hover {
                .arrowTrans {
                    transform: rotateX(180deg);
                }

                .showQR {
                    display: inline-block;
                    animation: open 1s;
                    animation-timing-function: ease;
                    img{
                        margin-top: 100px;
                    }
                    
                }
            }
        }
    }

    .mini-menu__outer {
        display: inline-block;
        animation: open 1s;
        animation-timing-function: ease;
    }
}

@keyframes open {
    from { right: -230px; }
    to { right: 0px; }
}

/* Media query to deactivate hover effect and hide .mini-menu__inner on screens below 1080px */
@media (max-width: 1080px) {
    .mini-menu:hover {
        .mini-menu__inner {
            display: none;
            animation: none;
        }

        .mini-menu__outer {
            animation: none;
        }
    }
}
