
// WEB 样式
body{
    background-color: #000;
}
.m3-002,.m3-005{
    width: 100%;
    min-width: 1280px;
    position: absolute;
    height: 100%;
    background-color: rgb(18, 18, 18);

    // header button 跳转主站
    .loginInButton{
        width: 140px;
        height: 40px;
        position: absolute;
        top: 2%;
        right: 5%;
        background: url(/static/images/m3-001/button.png) 0px 0px  no-repeat;
        background-size: cover;
        cursor: pointer;
    }
    .loginInButtonV2{
        width: 140px;
        height: 40px;
        position: absolute;
        top: 2%;
        right: 5%;
        background: url(/static/images/m3-001/qrpButtonNewWeb.png) 0px 0px  no-repeat;
        background-size: cover;
        cursor: pointer;
    }
    .container{
        width: 100%;
        height: 835px;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        background: url('/static/images/m3-001/desktop-slots.jpg?1')  0 0 no-repeat;
        background-size: 100% 100%;
        font-family: Roboto !important;
        // &.m3-001-container{
        //     background: url('/static/images/m3-001/2024-ERUO-theme-desktop.jpeg?1');
        //     background-position-x: 0%;
        //     background-position-y: 0%;
        //     background-repeat: no-repeat;
        //     background-size: 100%;
            
        // }
        .register-qrcode{
            position: relative;
            width: 1250px;
            margin: 200px auto 0;
            .ant-row{
                display: flex;
                justify-content: center;
                margin:  0 auto;

                // 注册
                .register-box,.web-register{
                    background: rgb(23, 23, 23);
                    margin: 40px 20px 40px 0px;
                    height: auto;
                    border-radius: 7px;
                    border: 1px solid rgb(12, 124, 0);
                    width: 33.33333333%;


                    .box-head{
                        background: url(/static/images/m3-001/SignUp-v1.png) center center  no-repeat;
                        background-size: 60%;
                        padding: 0 24px 10px;
                        height: 40px;
                    }
                    
                    .box-download-title{
                        background: url(/static/images/m3-001/Download-v1.png) center center  no-repeat;
                        background-size: 60%;
                        padding: 0 24px 10px;
                        height: 40px;
                    }

                    .registration-content{
                        text-align: center;
                        color: white;
                        padding: 10px 5px;
                        .tips{
                            color: rgb(12, 124, 0);
                            font-size: 16px;
                            font-weight: bold;
                            margin-bottom: 20px;
                            display: block;
                        }

                        .flexInput{
                            display: flex;
                            justify-content: center;
                            width: 80%;
                            margin: 10px auto;
                            position: relative;

                            .icon{
                                position: absolute;
                                z-index: 1;
                                width: 20px;
                                left: 12px;
                                top: 12px;
                                height: 20px;
                                display: inline-block;
                            }
                            input{
                                height: 40px;
                                line-height: 40px;
                                width: 100%;
                                border: 1px solid rgb(12, 124, 0) ;
                                border-radius: 5px;
                                padding-left: 13%;
                                background-color: transparent;
                                color: #959595;
                                outline: none;
                                &:focus{
                                    border-color: rgb(12, 124, 0);
                                }
                            }
                        }

                        // 错误提示隐藏
                        .phone-hit-error,.pass-hit-error,.name-hit-error{
                            // display: none;
                            font-size: 12px;
                            color: #ff0000;
                            width: 80%;
                            text-align: left;
                            margin: auto;
                        }

                        // 填写手机号码
                        .flexInput.phone-number{
                            display: flex;
                            justify-content: space-between;
                            .phone{
                                padding-left:0;
                                width: 16%;
                                align-items: center;
                                color: #FFF;
                                text-align: center;
                                border: 1px solid rgb(12, 124, 0);
                                background: transparent;
                            }
                            .phoneNumber{
                                width: 66%;
                            }
                            .icon3{
                                left: 80px;
                                width: 15px;
                            }
                        }

                        .tips2{
                            font-size: 12px;
                            margin: 5px auto;
                            color: #bbb;
                            font-weight: normal;
                        }

                        // 点击注册 提交

                        .btn-download-pc{
                            // background: url();
                            height: 40px;
                            line-height: 40px;
                            border-radius: 5px;
                            color: white;
                            border: 0px;
                            cursor: pointer;
                            background: url(/static/images/m3-001/QRP-button-desktop.png) 0px 0px  no-repeat;
                            background-size: 100% 100%;
                            margin-top: 13px;
                            width: 200px;
                        }

                        .loginInBtn{
                            position: absolute;
                            right: 5%;
                            color: rgb(13, 127, 0);
                            font-size: 14px;
                            top: 1.5rem;
                        }
                    }
                }

                .web-qrcode{
                    margin-right: 0;
                    .btnDownload{
                        display: flex;
                        justify-content: center;
                        margin-top: 40px;
                        .icon{
                            cursor: pointer;
                        }
                    }
                }


                // 下载
                .qrcode-content{
                    display: flex;
                    margin-top: 27px;
                    .textqrpdow{
                        color: rgb(255, 255, 255);
                        font-size: 20px;
                        padding: 30px 20px 15px 0px;
                        line-height: 26px;
                        margin-bottom: 15px;
                    }


                }
            }



            .ant-footer{
                display: flex;
                margin: 0 auto;
                border-radius: 7px;
                border: 1px solid rgb(12, 124, 0);
                width: 68.5%;
                overflow: hidden;
                img.footer-left{
                    width: 70%;
                    height: 138px;
                }
                .footer-right{
                    width: 30%;
                    background: #000;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    .title{
                        margin: 10px auto 10px 20px;
                        color: rgb(255, 255, 255);
                        font-size: 21px;
                    }
                    .cs{
                        display: flex;
                        align-items: center;
                        line-height: 30px;
                        color: rgb(12, 124, 0);
                        font-weight: bold;

                        .icon{
                            width: 20px;
                            margin:0 10px 0 20px;
                        }
                    }
                    .cs+.cs{
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .containerV2{
        width: 100%;
        height: 835px;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        background: url('/static/images/m3-001/LQRP_THEMEV2WEB.png')  0 0 no-repeat;
        background-size: 100% 100%;
        font-family: Roboto !important;
        .register-qrcode{
            position: relative;
            width: 1250px;
            margin: 200px auto 0;
            .ant-row{
                display: flex;
                justify-content: center;
                margin:  0 auto;
                // 注册
                .register-box,.web-register{
                    background: rgb(23, 23, 23);
                    margin: 40px 20px 40px 0px;
                    height: auto;
                    border-radius: 7px;
                    border: 1px solid #CC0505;
                    width: 33.33333333%;


                    .box-head{
                        background: url(/static/images/m3-001/SignUp-v1.png) center center  no-repeat;
                        background-size: 60%;
                        padding: 0 24px 10px;
                        height: 40px;
                    }

                    .box-download-title{
                        background: url(/static/images/m3-001/Download-v1.png) center center  no-repeat;
                        background-size: 60%;
                        padding: 0 24px 10px;
                        height: 40px;
                    }

                    .registration-content{
                        text-align: center;
                        color: white;
                        padding: 10px 5px;
                        .tips{
                            color: #CC0505;
                            font-size: 16px;
                            font-weight: bold;
                            margin-bottom: 20px;
                            display: block;
                        }

                        .flexInput{
                            display: flex;
                            justify-content: center;
                            width: 80%;
                            margin: 10px auto;
                            position: relative;

                            .icon{
                                position: absolute;
                                z-index: 1;
                                width: 20px;
                                left: 12px;
                                top: 12px;
                                height: 20px;
                                display: inline-block;
                            }
                            input{
                                height: 40px;
                                line-height: 40px;
                                width: 100%;
                                border: 1px solid #CC0505 ;
                                border-radius: 5px;
                                padding-left: 13%;
                                background-color: transparent;
                                color: #959595;
                                outline: none;
                                &:focus{
                                    border-color: #CC0505;
                                }
                                &::placeholder {
                                   // color: #CC0505;
                                    // Change to the desired color
                                }
                            }
                        }

                        // 错误提示隐藏
                        .phone-hit-error,.pass-hit-error,.name-hit-error{
                            // display: none;
                            font-size: 12px;
                            color: #ff0000;
                            width: 80%;
                            text-align: left;
                            margin: auto;
                        }

                        // 填写手机号码
                        .flexInput.phone-number{
                            display: flex;
                            justify-content: space-between;
                            .phone{
                                padding-left:0;
                                width: 16%;
                                align-items: center;
                                color: #FFF;
                                text-align: center;
                                border: 1px solid #CC0505;
                                background: transparent;
                            }
                            .phoneNumber{
                                width: 66%;
                            }
                            .icon3{
                                left: 80px;
                                width: 15px;
                            }
                        }

                        .tips2{
                            font-size: 12px;
                            margin: 5px auto;
                            color: #bbb;
                            font-weight: normal;
                        }

                        // 点击注册 提交

                        .btn-download-pc{
                            // background: url();
                            height: 40px;
                            line-height: 40px;
                            border-radius: 5px;
                            color: white;
                            border: 0px;
                            cursor: pointer;
                            background: url(/static/images/m3-001/qrpButtonMobileNew.png) 0px 0px  no-repeat;
                            background-size: 100% 100%;
                            margin-top: 13px;
                            width: 200px;
                        }

                        .loginInBtn{
                            position: absolute;
                            right: 5%;
                            color: #CC0505;
                            font-size: 14px;
                            top: 1.5rem;
                        }
                    }
                }

                .web-qrcode{
                    margin-right: 0;
                    .btnDownload{
                        display: flex;
                        justify-content: center;
                        margin-top: 40px;
                        .icon{
                            cursor: pointer;
                        }
                    }
                }


                // 下载
                .qrcode-content{
                    display: flex;
                    margin-top: 27px;
                    .textqrpdow{
                        color: rgb(255, 255, 255);
                        font-size: 20px;
                        padding: 30px 20px 15px 0px;
                        line-height: 26px;
                        margin-bottom: 15px;
                    }


                }
            }



            .ant-footer{
                display: flex;
                margin: 0 auto;
                border-radius: 7px;
                border: 1px solid #CC0505;
                width: 68.5%;
                overflow: hidden;
                img.footer-left{
                    width: 70%;
                    height: 138px;
                }
                .footer-right{
                    width: 30%;
                    background: #000;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    .title{
                        margin: 10px auto 10px 20px;
                        color: rgb(255, 255, 255);
                        font-size: 21px;
                    }
                    .cs{
                        display: flex;
                        align-items: center;
                        line-height: 30px;
                        color: #CC0505;
                        font-weight: bold;

                        .icon{
                            width: 20px;
                            margin:0 10px 0 20px;
                        }
                    }
                    .cs+.cs{
                        cursor: pointer;
                    }
                }
            }
        }
    }




    // 底部模块
    .Footer-Modal{
        background: #010101;
        .Footer-Nav-list{

            display: flex;
            width: 1286px;
            margin: 0 auto;
            padding-bottom: 50px;
            border-bottom: 1px solid #111;
            padding-top: 30px;
            justify-content: space-between;
            .licenseContainer{
                display: flex;
                flex-direction: column;
                color: #CCCCCC;
                span{
                    margin-bottom: 10px;
                }
            }
            .licenseContainer:nth-child(1){
                width: 25%;
            }
            .licenseContainer:nth-child(2){
                width: 50%;
            }
            .licenseContainer:nth-child(3){
                width: 16%;
            }
        }


        .footer-end_list{
			display: flex;
			justify-content: center;
			align-items: center;
			max-width: 1280px;
			padding: 1.5rem;
			font-size: 14px;
			margin: 0rem auto;
			flex-direction: column;
			.footer-end_list-box{
				display: flex;
				justify-content: center;
				align-items: center;
				.item{
					color: #999;
					padding: 0 1rem;
					cursor: pointer;
                    font-size: small;
                    text-decoration: none;
                    margin-bottom: 0.45rem;
                    &:hover{
                        color: #ccc;
                    }
					&+.item{
						border-left: 1px solid #303030;
					}
				}
			}
            span{
                display: block;
                width: 483px;
                text-align: center;
                margin: 20px auto 0;
                color: #666;
            }
        }

    }
}



.web-register-qrcode,.web-register{
    display: block;
}
.mobile-register{
    display: none;
}
.mobile-register-qrcode{
    display: none;
}
.mobile-qrcode{
    display: none;
}

// Mobile 样式
// 手机模式



@media (max-width:1080px) {
    .m3-002,.m3-005{
        width: 100%;
        min-width: initial;
        position: absolute;
        height: 100%;
        background: transparent;

        .container{
            width: 100%;
            height: auto;
            margin: 0 auto;
            background: url('/static/images/m3-001/mobile-slots.jpg?1')  0 0 no-repeat;
            background-size: 100% auto;
            // &.m3-001-container{
            //     background: url('/static/images/m3-001/2024-ERUO-theme-mobile.webp?1') 0 0 no-repeat;
            //     background-size: 100% auto;
            // }
            .web-register-qrcode,.web-register ,.web-qrcode{
                display: none;
            }

            .mobile-register-qrcode , .mobile-register , .mobile-qrcode{
                display: flex;
                width: 100%;
                justify-content: center;
                flex-direction: column;
            }

            // 注册
            .mobile-register{
                margin: 95% auto 0;
                flex-direction: column;
                width: 90%;


                .flexInput{
                    display: flex;
                    justify-content: center;
                    width: 100%;
                    margin: 10px auto;
                    position: relative;

                    .icon{
                        position: absolute;
                        z-index: 1;
                        width: 20px;
                        left: 12px;
                        top: 12px;
                        height: 20px;
                        display: inline-block;
                    }
                    input{
                        height: 40px;
                        line-height: 40px;
                        width: 100%;
                        border: 1px solid rgb(12, 124, 0) ;
                        border-radius: 5px;
                        padding-left: 13%;
                        background-color: transparent;
                        color: #959595;
                        outline: none;
                        &:focus{
                            border-color: rgb(12, 124, 0);
                        }
                    }
                }

                .tips2{
                    font-size: 12px;
                    margin: 5px auto;
                    color: #bbb;
                    font-weight: normal;
                }

                .btn-download-pc{
                    // background: url();
                    height: 40px;
                    line-height: 40px;
                    border-radius: 5px;
                    color: white;
                    border: 0px;
                    cursor: pointer;
                    background: url(/static/images/m3-001/QRP-button-desktop.png) 0px 0px  no-repeat;
                    background-size: 100% 100%;
                    margin: 13px auto;
                    width: 70%;
                }
            }

            // 下载
            .mobile-qrcode{
                margin: 0 auto;
                flex-direction: column;
                width: 90%;
                .down-banner{
                    margin: 30px 0;
                }
                .downText{
                    width: 100%;
                    line-height: .88rem;
                    text-align: center;
                    color: #fff;
                    background: #0d7f00;
                    border-radius: 0.5rem;
                    margin: 0 auto 1.5rem;
                    height: 44px;
                    line-height: 44px;
                }
            }

            .phone-hit-error , .email-hit-error , .pass-hit-error , .name-hit-error{
                font-size: 12px;
                color: #ff0000;
            }
        }


        .containerV2{
            width: 100%;
            height: auto;
            margin: 0 auto;
            background: url('/static/images/m3-001/LQRP_THEMEV2.png')  0 0 no-repeat;
            background-size: 100% auto;
            .web-register-qrcode,.web-register ,.web-qrcode{
                display: none;
            }

            .mobile-register-qrcode , .mobile-register , .mobile-qrcode{
                display: flex;
                width: 100%;
                justify-content: center;
                flex-direction: column;
            }

            // 注册
            .mobile-register{
                // margin: 95% auto 0;
                flex-direction: column;
                width: 90%;
                margin-top: calc(20vh + 17rem);
                margin-right: auto;
                margin-left: auto;
                overflow: hidden;
            


                .flexInput{
                    display: flex;
                    justify-content: center;
                    width: 100%;
                    margin: 10px auto;
                    position: relative;

                    .icon{
                        position: absolute;
                        z-index: 1;
                        width: 20px;
                        left: 12px;
                        top: 12px;
                        height: 20px;
                        display: inline-block;
                    }
                    input{
                        height: 40px;
                        line-height: 40px;
                        width: 100%;
                        border: 1px solid #CC0505 ;
                        border-radius: 5px;
                        padding-left: 13%;
                        background-color: transparent;
                        color: #959595;
                        outline: none;
                        &:focus{
                            border-color: #CC0505 ;
                        }
                        &::placeholder {
                            color: #CC0505;
                            // Change to the desired color
                        }
                        
                    }
                }

                .tips2{
                    font-size: 12px;
                    margin: 5px auto;
                    color: #CC0505;
                    font-weight: normal;
                }

                .btn-download-pc{
                    // background: url();
                    height: 40px;
                    line-height: 40px;
                    border-radius: 5px;
                    color: white;
                    border: 0px;
                    cursor: pointer;
                    background: url(/static/images/m3-001/qrpButtonMobileNew.png) 0px 0px  no-repeat;
                    background-size: 100% 100%;
                    margin: 13px auto;
                    width: 70%;
                }
            }

            // 下载
            .mobile-qrcode{
                margin: 0 auto;
                flex-direction: column;
                width: 90%;
                .down-banner{
                    margin: 30px 0;
                }
                .downText{
                    width: 100%;
                    line-height: .88rem;
                    text-align: center;
                    color: #fff;
                    background: #CC0505;
                    border-radius: 0.5rem;
                    margin: 0 auto 1.5rem;
                    height: 44px;
                    line-height: 44px;
                }
            }

            .phone-hit-error , .email-hit-error , .pass-hit-error , .name-hit-error{
                font-size: 12px;
                color: #ff0000;
            }
        }


        .Footer-Modal{
            display: none;
        }
    }
}


@media screen and (max-width:600px) {
    .m3-002,.m3-005{
        .loginInButton{
            width: 100px;
            height: 28px;
            top: .6%;
            right: 1%;
        }
        .loginInButtonV2{
            width: 100px;
            height: 28px;
            top: .6%;
            right: 1%;
            margin-right: 0.3rem
        }
        .container{
            .mobile-register{
                margin-top: 95%;
            }
        }
    }
}







