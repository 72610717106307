// m3-003


.m3-003{
    width: 100%;
    min-width: 1280px;
    position: absolute;
    height: 100%;

    // web 显示
    .web-m3-003{
        .topweb{
            background-color: #212121;
            display: flex;
            align-items: center;
            padding: 15px;
            box-sizing: border-box;
            img{
                width: 10rem;
                cursor: pointer;
            }
        }
        .tppmobile{
            display: none;
        }

        // banner
        .img_wrap{
            img{
                object-fit:cover;
                height: 100%;
                width: 100%;
                vertical-align: middle;
            }
        }



        // 注册
        .hayhar-middle{
            display: flex;
            max-width: 1280px;
            justify-content: center;
            margin: 0 auto;
    
            .register-box{
                width: 45%;
                margin-top: 20px;
                margin-bottom: 20px;
                padding: 0 10px;
                .box-head{
                    padding: .5rem 1.25rem;
                    border-radius: .25rem .25rem 0 0 ;
                    position: relative;
                    color: rgb(26,255,0,);
                    background: linear-gradient(-71deg,#212121 300px,#111 0) #000;
                    border:1px solid rgb(13,127,0);
                    border-bottom: none;
            
                    .icon{
                        position:absolute;
                        top: 1px;
                        left: 0;
                    }
                    .icon2{
                        width: 45px;
                        height: 45px;
                        position:absolute;
                        top: 8px;
                        left: 8px;
                    }
                }
                .tips{
                    text-align: center;
                    color: #fff;
                    font-size: 12px;
                    margin: 20px auto;
                }

                .tips.tips2,.tips3{
                    margin: 10px auto;
                }

                .registration-content , .qrcode-content{
                    border: 1px solid #ccc;
                    border-top: 0;
                    padding: 0 24px;
                    border-radius: 0 0 .25rem .25rem;
                    display: flex;
                    flex-direction: column;
                    background: rgb(23, 23, 23);
                    box-sizing: border-box;
                    border: 1px solid rgb(13,127,0);
                    border-top: none;
                    height: 28rem;
                    .phone-number,.flexInput{
                        display: flex;
                        flex-wrap: nowrap;
                        align-items: center;
                        label{
                            color: #979797;
                            font-size: 14px;
                            width: 50%;
                        }
                    }

                    .flexInput{
                        width: 25rem;
                        margin:  0 auto;
                        background: #2a2a2a;
                        border-radius: 5px;
                        border-bottom: none;
                        height: 46px;
                        padding-left: 2%;
                        display: flex;
                        align-items: center;
                    }
                    
                    input{
                        outline: none;
                        height: 44px;
                        line-height: 44px;
                        border-radius: 5px;
                        padding-left: 2%;
                        
                        background-color: transparent;
                        border: none;
                        width: 100%;
                        color: #959595;
                        &:focus{
                            border-color: #2563eb ;
                        }
                    }

                    .register-submit {
                        height: 44px;
                        width: 50%;
                        margin: 0 auto;
                        background-color: rgb(13, 127, 0);
                        cursor: pointer;
                        border-radius: 5px;
                        color: #FFF;
                        border: none;
                    }
                }
            
            
                .phone-hit-error , .email-hit-error , .pass-hit-error , .name-hit-error{
                    font-size: 12px;
                    color: #ff0000;
                    margin: 0.5rem auto;
                    width: 25rem;
                    filter: contrast(1);
                    font-weight: bold;
                }
            
                .qrcode-content{
                    align-items: flex-start;
                    flex-direction:row;
                    justify-content: initial;
                    padding-top: 24px;
                    .Down,.down-tips{
                        display: flex;
                        flex-direction: row;
                        width: 100%;
                        justify-content: space-between;
                    }
                    .Down{
                        flex-direction: column;
                    }
                    .down-tips{
                        p{
                            width: 50%;
                            text-align: center;
                            font-size: 13px;
                            color: grey;
                            margin-top: 10px;
                        }
                        justify-content: center;
                        align-items: center;
                    }
                    .btnDownload{
                        display: flex;
                        color: #333;
                        cursor: pointer;
                        border-radius: 4px;
                        align-items: center;
                        color: #FFF;
                        margin-top: 10px;
                        img{
                            width: 55%;
                        }
                    }
            
                
            
                    .installation{
                        height: 6.5rem;
                        list-style: none;
                        font-size: 14px;
                        color: #FFF;
                        margin: 0px 0px 0  0px;
                        border-bottom: 1px solid rgb(68, 214, 45);
                        li{
                            line-height: 1.3;
                            margin-bottom: 10px;
                        }
                    }
                }    
            }
        }


        .Footer-Modal{
            background: #010101;
            .Footer-Nav-list{

                display: flex;
                width: 62%;
                margin: 0 auto;
                padding-bottom: 50px;
                border-bottom: 1px solid #111;
                padding-top: 30px;
                justify-content: space-between;
                .licenseContainer{
                    display: flex;
                    flex-direction: column;
                    color: #CCCCCC;
                    span{
                        margin-bottom: 10px;
                    }
                }
                .licenseContainer:nth-child(1){
                    width: 25%;
                }
                .licenseContainer:nth-child(2){
                    width: 50%;
                }
                .licenseContainer:nth-child(3){
                    width: 16%;
                }
            }


            .footer-end_list{
                display: flex;
                justify-content: center;
                align-items: center;
                max-width: 1280px;
                padding: 1.5rem;
                font-size: 14px;
                margin: 0rem auto;
                flex-direction: column;
                .footer-end_list-box{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .item{
                        color: #999;
                        padding: 0 1rem;
                        cursor: pointer;
                        font-size: small;
                        text-decoration: none;
                        margin-bottom: 0.45rem;
                        &:hover{
                            color: #ccc;
                        }
                        &+.item{
                            border-left: 1px solid #303030;	
                        }
                    }
                }
                span{
                    display: block;
                    width: 483px;
                    text-align: center;
                    margin: 20px auto 0;
                    color: #666;
                }
            }
            
        }
    }
}




// mobile ipad
// Mobile 样式
// 手机模式



@media (max-width:1080px) {
    .m3-003{
        min-width:auto;
       .web-m3-003{
            .topweb{
                display: none;
            }
            .ant-carousel{
                display: none;
            }
            .Footer-Modal{
                display: none;
            }
            .web-qrcode,.web-register{
                display: none;
            }

            width: 100%;
            height: auto;
            margin: 0 auto;
            background: url(/static/images/m3-003/bgother6.jpg) 0 0 no-repeat;
            background-size: 100% auto;
            .hayhar-middle{
                flex-direction: column;

                .mobile-qrp-title{
                    height: 26px;
                    color: #FFF;
                    margin: 0 auto;
                    line-height: 26px;
                }

                .mobile-register{
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    margin: 95% auto 0;
                    flex-direction: column;
                    width: 90%;
                    .flexInput{
                        display: flex;
                        justify-content: center;
                        width: 100%;
                        margin: 10px auto;
                        position: relative;
                        
                        .icon{
                            position: absolute;
                            z-index: 1;
                            width: 20px;
                            left: 12px;
                            top: 12px;
                            height: 20px;
                            display: inline-block;
                        }
                        input{
                            height: 40px;
                            line-height: 40px;
                            width: 100%;
                            border: 1px solid rgb(12, 124, 0) ;
                            border-radius: 5px;
                            padding-left: 5%;
                            background-color: transparent;
                            color: #fff;
                            outline: none;
                            font-size: 15px;
                            &:focus{
                                border-color: rgb(12, 124, 0);
                            }
                        }
                    }
    
                    .tips2{
                        font-size: 12px;
                        margin: 5px auto;
                        color: #bbb;
                        font-weight: normal;
                    }
    
                    .btn-download-pc{
                        // background: url();
                        height: 40px;
                        line-height: 40px;
                        border-radius: 5px;
                        color: white;
                        border: 0px;
                        cursor: pointer;
                        margin: 13px auto;
                        width: 70%;
                        background-color: #0d7f00;
                    }
                }
    
    
                .mobile-qrcode{
                    display: flex;
                    width: 100%;
                    justify-content: center;
                    flex-direction: column;
                    margin: 0 auto;
                    flex-direction: column;
                    width: 90%;
                    .down-banner{
                        margin: 30px 0;
                    }
                    .downText{
                        width: 100%;
                        line-height: .88rem;
                        text-align: center;
                        color: #fff;
                        background: #0d7f00;
                        border-radius: 0.5rem;
                        margin: 0 auto 1.5rem;  
                        height: 44px;
                        line-height: 44px;
                    }
                }

                .phone-hit-error , .email-hit-error , .pass-hit-error , .name-hit-error{
                    font-size: 12px;
                    color: #ff0000;
                }
            }
            
       }
        
    }
   
}



