.APPDownload{
    display: flex;
    align-items: center;
    padding: .25rem;
    box-sizing: border-box;
    justify-content: space-between;

    .APPDownload-2{
        display: flex;
        align-items: center;
        .app_header_close{
            display: block;
            width: 17px;
            height: 17px;
            border: 0;
            font-size: 20px;
            text-align: center;
            text-decoration: none;
            line-height: 17px;
            color: #4e4c4c;
            font-weight: 900;
            margin-left: .5rem;
    
        }
        .APPlogo{
            width: 4rem;
            margin-left: .5rem;
        }
        .app_banner_info{
            display: flex;
            flex-direction: column;
            font-size: 14px;
            font-weight: normal;
            color: #6b6868;
            margin-left: .5rem;
            justify-content: space-between;
            .app_banner_title{
                font-size: 14px;
                line-height: 17px;
                color: #000;
                font-weight: bold;
            }
            .star{
                display: flex;
                justify-content: space-between;
            }
            .app_banner_rating{
                width: 10px;
            }
        }
    }
    .button-link{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .downlink,.website{
            width: 100px;
            height: 24px;
            line-height: 24px;
            font-weight: bold;
            color: #25AAE1;
            background: #FFF;
            border: 1px solid #ddd;
            font-size: 14px;
            text-align: center;
            margin-top: .25rem;
        }
        .downlink{
           border: 1px solid #25AAE1;
        }
    }
}
