
.m3-004{
    // header button 跳转主站
    .m3-004-loginInButton{
        width: 140px;
        height: 35px;
        position: absolute;
        top: 2%;
        right: 8%;
        background: url(/static/images/m3-004/CXJ1-2193-login-button.png) 0px 0px  no-repeat;
        background-size: contain;
        cursor: pointer;
        z-index: 1000;

        @media (max-width: 1080px){
            width: 145px;
            height: 45px;
            top: 3.5%;
            right: 5%;
            z-index: 1000;
        }
    }

    .container{
        width: 100%;
        height: 735px;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        // background: url('/static/images/m3-001/desktop-slots.jpg?1')  0 0 no-repeat;
        background-size: 100% 100%;
        font-family: "Calibri Light", Calibri, sans-serif !important;
        &.m3-004-container{
            background: url('/static/images/m3-004/CXJ1-2193-desktop.png?1');
            background-position-x: 0%;
            background-position-y: 0%;
            background-repeat: no-repeat;
            background-size: 100%;
        }
        .register-qrcode{
            position: relative;
            width: 1250px;
            margin-left: 25%;

            .register-column{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin:  0 auto;

                .web-only-tagline {
                    margin-top: 105px;
                    width: 70%;

                    @media (max-width: 1400px){
                        margin-top: 60px;
                    }
                }

                // 注册
                .register-box,.web-register{
                    background: rgb(23, 23, 23);
                    margin: 10px 20px 40px 0px;
                    height: auto;
                    border-radius: 20px;
                    border: 2px solid rgb(74, 180, 62);
                    width: 33.33333333%;
                    min-width: 400px;
                    
                    .box-head{
                        background: url(/static/images/m3-004/CXJ1-2193-register-header.png) center center  no-repeat;
                        background-size: 60%;
                        padding: -1px 24px 10px;
                        height: 40px;
                    }
                    
                    .registration-content{
                        text-align: center;
                        color: white;
                        padding: 10px 5px;
                        .tips{
                            color: rgb(6, 255, 12);
                            font-size: 12px;
                            font-weight: bold;
                            display: block;
                        }
                        .tips-sqrp{
                            font-size: 14px;
                        }

                        .flexInput{
                            display: flex;
                            justify-content: center;
                            width: 80%;
                            margin: 10px auto;
                            position: relative;

                            .icon{
                                position: absolute;
                                z-index: 1;
                                width: 20px;
                                left: 12px;
                                top: 12px;
                                height: 20px;
                                display: inline-block;
                            }
                            input{
                                font-family: "Calibri Light", Calibri, sans-serif !important;
                                height: 40px;
                                line-height: 40px;
                                width: 100%;
                                border: 1px solid rgb(12, 124, 0) ;
                                border-radius: 10px;
                                padding-left: 13%;
                                background-color: #363636;
                                color: #959595;
                                outline: none;
                                &:focus{
                                    border-color: rgb(12, 124, 0);
                                }
                            }
                            .password-input {
                                width: 100%;
                                padding-right: 40px; 
                            }
                            
                            .pwd-eye-icon {
                                position: absolute;
                                right: 10px; 
                                top: 50%;
                                transform: translateY(-50%);
                                cursor: pointer;
                                width: 20px;
                                height: 20px; 
                            }
                        }

                        // 错误提示隐藏
                        .phone-hit-error,.pass-hit-error,.name-hit-error{
                            // display: none;
                            font-size: 12px;
                            color: #ff0000;
                            width: 80%;
                            text-align: left;
                            margin: auto;
                        }

                        // 填写手机号码
                        .flexInput.phone-number{
                            display: flex;
                            justify-content: space-between;
                            .phone{
                                padding-left:0;
                                width: 16%;
                                align-items: center;
                                color: #FFF;
                                text-align: center;
                                border: 1px solid rgb(12, 124, 0);
                                background: #363636;
                            }
                            .phoneNumber{
                                width: 66%;
                            }
                            .icon3{
                                left: 80px;
                                width: 15px;
                            }
                        }

                        .tips2{
                            font-size: 12px;
                            margin: 5px auto;
                            color: #e8e8e8;
                            font-weight: normal;
                        }
                        .tips2-sqrp{
                            font-size: 14px;
                        }

                        // 点击注册 提交
                        .btn-download-pc{
                            width: 170px;
                            height: 45px;
                            line-height: 40px;
                            border-radius: 5px;
                            color: white;
                            border: 0px;
                            cursor: pointer;
                            background: url(/static/images/m3-004/CXJ1-2193-join-button.png) 0px 0px  no-repeat;
                            background-size: contain;
                            margin-top: 13px;

                        }

                        .loginInBtn{
                            position: absolute;
                            right: 5%;
                            color: rgb(6, 255, 12);
                            font-size: 14px;
                            top: 1.5rem;
                        }
                    }
                }

            }

            .ant-footer{
                display: flex;
                margin: 0 auto;
                border-radius: 7px;
                border: 1px solid rgb(12, 124, 0);
                width: 68.5%;
                overflow: hidden;
                img.footer-left{
                    width: 70%;
                    height: 138px;
                }
                .footer-right{
                    width: 30%;
                    background: #000;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    .title{
                        margin: 10px auto 10px 20px;
                        color: rgb(255, 255, 255);
                        font-size: 21px;
                    }
                    .cs{
                        display: flex;
                        align-items: center;
                        line-height: 30px;
                        color: rgb(12, 124, 0);
                        font-weight: bold;

                        .icon{
                            width: 20px;
                            margin:0 10px 0 20px;
                        }
                    }
                    .cs+.cs{
                        cursor: pointer;
                    }
                }
            }
        }
        
        @media (max-width: 1080px) {
            .dimmed-overlay {
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.5);
                z-index: 1001;
            }
        }
        
    }

    .containerV2{
        width: 100%;
        height: 835px;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        background: url('/static/images/m3-001/LQRP_THEMEV2WEB.png')  0 0 no-repeat;
        background-size: 100% 100%;
        font-family: Roboto !important;
        .register-qrcode{
            position: relative;
            width: 1250px;
            margin: 200px auto 0;
            
            .register-column{
                display: flex;
                justify-content: center;
                margin:  0 auto;
                // 注册
                .register-box .web-register{
                    background: rgb(23, 23, 23);
                    margin: 40px 20px 40px 0px;
                    height: auto;
                    border-radius: 7px;
                    border: 1px solid #CC0505;
                    width: 33.33333333%;


                    .box-head{
                        background: url(/static/images/m3-001/SignUp-v1.png) center center no-repeat;
                        background-size: 60%;
                        padding: 0 24px 10px;
                        height: 40px;
                    }

                    .box-download-title{
                        background: url(/static/images/m3-001/Download-v1.png) center center  no-repeat;
                        background-size: 60%;
                        padding: 0 24px 10px;
                        height: 40px;
                    }

                    .registration-content{
                        text-align: center;
                        color: white;
                        padding: 10px 5px;
                        .tips{
                            color: #CC0505;
                            font-size: 16px;
                            font-weight: bold;
                            margin-bottom: 20px;
                            display: block;
                        }

                        .flexInput{
                            display: flex;
                            justify-content: center;
                            width: 80%;
                            margin: 10px auto;
                            position: relative;

                            .icon{
                                position: absolute;
                                z-index: 1;
                                width: 20px;
                                left: 12px;
                                top: 12px;
                                height: 20px;
                                display: inline-block;
                            }
                            input{
                                height: 40px;
                                line-height: 40px;
                                width: 100%;
                                border: 1px solid #CC0505 ;
                                border-radius: 5px;
                                padding-left: 13%;
                                background-color: transparent;
                                color: #959595;
                                outline: none;
                                &:focus{
                                    border-color: #CC0505;
                                }
                                &::placeholder {
                                   // color: #CC0505;
                                    // Change to the desired color
                                }
                            }
                        }

                        // 错误提示隐藏
                        .phone-hit-error,.pass-hit-error,.name-hit-error{
                            // display: none;
                            font-size: 12px;
                            color: #ff0000;
                            width: 80%;
                            text-align: left;
                            margin: auto;
                        }

                        // 填写手机号码
                        .flexInput.phone-number{
                            display: flex;
                            justify-content: space-between;
                            .phone{
                                padding-left:0;
                                width: 16%;
                                align-items: center;
                                color: #FFF;
                                text-align: center;
                                border: 1px solid #CC0505;
                                background: transparent;
                            }
                            .phoneNumber{
                                width: 66%;
                            }
                            .icon3{
                                left: 80px;
                                width: 15px;
                            }
                        }

                        .tips2{
                            font-size: 12px;
                            margin: 5px auto;
                            color: #bbb;
                            font-weight: normal;
                        }

                        // 点击注册 提交

                        .btn-download-pc{
                            // background: url();
                            height: 40px;
                            line-height: 40px;
                            border-radius: 5px;
                            color: white;
                            border: 0px;
                            cursor: pointer;
                            background: url(/static/images/m3-001/qrpButtonMobileNew.png) 0px 0px  no-repeat;
                            background-size: 100% 100%;
                            margin-top: 13px;
                            width: 200px;
                        }

                        .loginInBtn{
                            position: absolute;
                            right: 5%;
                            color: #CC0505;
                            font-size: 14px;
                            top: 1.5rem;
                        }
                    }
                }

                .web-qrcode{
                    margin-right: 0;
                    .btnDownload{
                        display: flex;
                        justify-content: center;
                        margin-top: 40px;
                        .icon{
                            cursor: pointer;
                        }
                    }
                }

                // 下载
                .qrcode-content{
                    display: flex;
                    margin-top: 27px;
                    .textqrpdow{
                        color: rgb(255, 255, 255);
                        font-size: 20px;
                        padding: 30px 20px 15px 0px;
                        line-height: 26px;
                        margin-bottom: 15px;
                    }
                }
            }


            .ant-footer{
                display: flex;
                margin: 0 auto;
                border-radius: 7px;
                border: 1px solid #CC0505;
                width: 68.5%;
                overflow: hidden;
                img.footer-left{
                    width: 70%;
                    height: 138px;
                }
                .footer-right{
                    width: 30%;
                    background: #000;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    .title{
                        margin: 10px auto 10px 20px;
                        color: rgb(255, 255, 255);
                        font-size: 21px;
                    }
                    .cs{
                        display: flex;
                        align-items: center;
                        line-height: 30px;
                        color: #CC0505;
                        font-weight: bold;

                        .icon{
                            width: 20px;
                            margin:0 10px 0 20px;
                        }
                    }
                    .cs+.cs{
                        cursor: pointer;
                    }
                }
            }
        }
    }


    // 底部模块
    .Footer-Modal{
        background: #010101;
        .Footer-Nav-list{

            display: flex;
            width: 1286px;
            margin: 0 auto;
            padding-bottom: 50px;
            border-bottom: 1px solid #111;
            padding-top: 30px;
            justify-content: space-between;
            .licenseContainer{
                display: flex;
                flex-direction: column;
                color: #CCCCCC;
                span{
                    margin-bottom: 10px;
                }
            }
            .licenseContainer:nth-child(1){
                width: 25%;
            }
            .licenseContainer:nth-child(2){
                width: 50%;
            }
            .licenseContainer:nth-child(3){
                width: 16%;
            }
        }


        .footer-end_list{
			display: flex;
			justify-content: center;
			align-items: center;
			max-width: 1280px;
			padding: 1.5rem;
			font-size: 14px;
			margin: 0rem auto;
			flex-direction: column;
			.footer-end_list-box{
				display: flex;
				justify-content: center;
				align-items: center;
				.item{
					color: #999;
					padding: 0 1rem;
					cursor: pointer;
                    font-size: small;
                    text-decoration: none;
                    margin-bottom: 0.45rem;
                    &:hover{
                        color: #ccc;
                    }
					&+.item{
						border-left: 1px solid #303030;
					}
				}
			}
            span{
                display: block;
                width: 483px;
                text-align: center;
                margin: 20px auto 0;
                color: #666;
            }
        }

    }
}


.web-register-qrcode,.web-register{
    display: block;
}
.mobile-register{
    display: none;
}
.mobile-register-qrcode{
    display: none;
}
.mobile-qrcode{
    display: none;
}
.mobile-only-tagline {
    display: none;
}
.m3-004-mobileDownload{
    display: none;
}

// Mobile 样式
// 手机模式
@media (max-width:1080px) {
    .m3-004 {
        width: 100%;
        min-width: initial;
        position: absolute;
        height: 100%;
        background: transparent;

        .container{
            position: relative;
            width: 100%;
            height: auto;
            margin: 0 auto;
            background-size: 100% auto;
            &.m3-004-container{
                background: url('/static/images/m3-004/CXJ1-2193-mobile.png?1') 0 0 no-repeat;
                background-size: 100% auto;
            }
            .web-register-qrcode{
                margin: 95% auto 40px;
                flex-direction: column;
                width: 95%;

                .web-only-tagline {
                    display: none;
                }
                .register-column{
                    width: 50px;
                    margin: 0 auto;

                    .web-register{
                        background: rgb(23, 23, 23);
                        margin: 10px 20px 0px;
                        height: auto;
                        border-radius: 20px;
                        border: 2px solid rgb(74, 180, 62);
                        width: 33.33333333%;
                        min-width: 340px;
    
                        .registration-content{
                            text-align: center;
                            color: white;
                            padding: 10px 5px;
                            .tips{
                                color: rgb(6, 255, 12);
                                font-size: 12px;
                                font-weight: bold;
                                display: block;
                            }
                            .tips-sqrp{
                                font-size: 14px;
                            }
    
                            .flexInput{
                                display: flex;
                                justify-content: center;
                                width: 80%;
                                margin: 10px auto;
                                position: relative;
    
                                .icon{
                                    position: absolute;
                                    z-index: 1;
                                    width: 20px;
                                    left: 10px;
                                    top: 10px;
                                    height: 20px;
                                    display: inline-block;
                                }
                                input{
                                    font-family: "Calibri Light", Calibri, sans-serif !important;
                                    height: 40px;
                                    line-height: 40px;
                                    width: 100%;
                                    border: 1px solid rgb(12, 124, 0) ;
                                    border-radius: 10px;
                                    padding-left: 13%;
                                    background-color: #363636;
                                    color: #959595;
                                    outline: none;
                                    &:focus{
                                        border-color: rgb(12, 124, 0);
                                    }
                                }
                                .password-input {
                                    width: 100%;
                                    padding-right: 40px; 
                                }
                                
                                .pwd-eye-icon {
                                    position: absolute;
                                    right: 10px; 
                                    top: 50%;
                                    transform: translateY(-50%);
                                    cursor: pointer;
                                    width: 20px;
                                    height: 20px; 
                                }
                            }
    
                            // 错误提示隐藏
                            .phone-hit-error,.pass-hit-error,.name-hit-error{
                                // display: none;
                                font-size: 12px;
                                color: #ff0000;
                                width: 80%;
                                text-align: left;
                                margin: auto;
                            }
    
                            // 填写手机号码
                            .flexInput.phone-number{
                                display: flex;
                                justify-content: space-between;
                                .phone{
                                    padding-left:0;
                                    width: 16%;
                                    align-items: center;
                                    color: #FFF;
                                    text-align: center;
                                    border: 1px solid rgb(12, 124, 0);
                                    background: #363636;
                                }
                                .phoneNumber{
                                    width: 66%;
                                }
                                .icon3{
                                    left: 65px;
                                    width: 15px;
                                }
                            }
    
                            .tips2{
                                font-size: 12px;
                                margin: 5px auto;
                                color: #e8e8e8;
                                font-weight: normal;
                            }

                            .tips2-sqrp{
                                font-size: 14px;
                            }
    
                            // 点击注册 提交
                            .btn-download-pc{
                                width: 150px;
                                height: 40px;
                                line-height: 40px;
                                border-radius: 5px;
                                color: white;
                                border: 0px;
                                cursor: pointer;
                                background: url(/static/images/m3-004/CXJ1-2193-join-button.png) 0px 0px  no-repeat;
                                background-size: contain;
                                margin-top: 13px;
    
                            }
    
                            .loginInBtn{
                                position: absolute;
                                right: 5%;
                                color: rgb(6, 255, 12);
                                font-size: 14px;
                                top: 1.5rem;
                            }
                        }
                    }
                }
            }
            
            .mobile-only-tagline {
                display: block;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                top: 0;
                text-align: center; 
                margin-top: 10%; 
                width: 80%;

                &-img {
                    width: 100%; 
                    height: auto;
                }
            }
    
            // Mobile版注册
            .mobile-register{
                display: none;
            }

            // 下载
            .m3-004-mobileDownload{
                display: flex;
                margin: 10px auto 0;
                flex-direction: column;
                width: 100%;
                .down-banner{
                    margin: 30px 0;
                }
                .downText{
                    width: 95%;
                    line-height: .88rem;
                    text-align: center;
                    color: #fff;
                    background: #0d7f00;
                    border-radius: 0.5rem;
                    margin: 0 auto 1.5rem;
                    height: 44px;
                    line-height: 44px;
                }
                .web-qrcode{
                    display: none;
                }
            }
            
            .phone-hit-error , .email-hit-error , .pass-hit-error , .name-hit-error{
                font-size: 12px;
                color: #ff0000;
            }
        }

        .containerV2{
            width: 100%;
            height: auto;
            margin: 0 auto;
            background: url('/static/images/m3-001/LQRP_THEMEV2.png')  0 0 no-repeat;
            background-size: 100% auto;
            .web-register-qrcode,.web-register ,.web-qrcode{
                display: none;
            }

            .mobile-register-qrcode , .mobile-register , .mobile-qrcode{
                display: flex;
                width: 100%;
                justify-content: center;
                flex-direction: column;
            }

            // 注册
            .mobile-register{
                // margin: 95% auto 0;
                flex-direction: column;
                width: 90%;
                margin-top: calc(20vh + 17rem);
                margin-right: auto;
                margin-left: auto;
                overflow: hidden;
            
                .flexInput{
                    display: flex;
                    justify-content: center;
                    width: 100%;
                    margin: 10px auto;
                    position: relative;

                    .icon{
                        position: absolute;
                        z-index: 1;
                        width: 20px;
                        left: 12px;
                        top: 12px;
                        height: 20px;
                        display: inline-block;
                    }
                    input{
                        height: 40px;
                        line-height: 40px;
                        width: 100%;
                        border: 1px solid #CC0505 ;
                        border-radius: 5px;
                        padding-left: 13%;
                        background-color: transparent;
                        color: #959595;
                        outline: none;
                        &:focus{
                            border-color: #CC0505 ;
                        }
                        &::placeholder {
                            color: #CC0505;
                            // Change to the desired color
                        }
                        
                    }
                }

                .tips2{
                    font-size: 12px;
                    margin: 5px auto;
                    color: #CC0505;
                    font-weight: normal;
                }

                .btn-download-pc{
                    // background: url();
                    height: 40px;
                    line-height: 40px;
                    border-radius: 5px;
                    color: white;
                    border: 0px;
                    cursor: pointer;
                    background: url(/static/images/m3-001/qrpButtonMobileNew.png) 0px 0px  no-repeat;
                    background-size: 100% 100%;
                    margin: 13px auto;
                    width: 70%;
                }
            }

            // 下载
            .mobile-qrcode{
                margin: 0 auto;
                flex-direction: column;
                width: 90%;
                .down-banner{
                    margin: 30px 0;
                }
                .downText{
                    width: 100%;
                    line-height: .88rem;
                    text-align: center;
                    color: #fff;
                    background: #CC0505;
                    border-radius: 0.5rem;
                    margin: 0 auto 1.5rem;
                    height: 44px;
                    line-height: 44px;
                }
            }

            .phone-hit-error , .email-hit-error , .pass-hit-error , .name-hit-error{
                font-size: 12px;
                color: #ff0000;
            }
        }

        .Footer-Modal{
            display: none;
        }
    }
    
}

@media screen and (max-width:600px) {
    .m3-004 {
        .m3-004-loginInButton{
            width: 100px;
            height: 28px;
            top: 0.7%;
            right: 3%;
        }
        .loginInButtonV2{
            width: 100px;
            height: 28px;
            top: .6%;
            right: 1%;
            margin-right: 0.3rem
        }

    }
}

.ant-modal-centered{
    position: fixed;
    top:0;
    right: 0;
    bottom: 0;
    left:0;
    z-index: 1;
    overflow: auto;
    outline: 0;
    background-color: rgba(34, 34, 34, .45);
}

.ant-modal-centered .ant-modal{
    top: 0;
    display: inline-block;
    text-align: left;
    vertical-align: middle;
}


//滑动验证
.fpcaptch-modal {
    z-index: 2002;
    width: 332px !important;
    .ant-modal-body .ant-spin {
        display: block;
        opacity: 0.6;
        position: absolute;
        border-radius: 10px;
        left:30%;
        top: 50%;
        bottom: 0;
        right: 0;
        // background: #00000036;
        z-index: 11;
    }
    .ant-modal-body .ant-spin.hide,
    .user-center-loading.ant-spin.hide {
        display: none !important;
    }
    .ant-modal-content {
        position: relative;
        background-color: #fff;
        background-clip: padding-box;
        border: 0;
        border-radius: 10px;
        box-shadow: 0 0 1px 1px rgba(0, 0, 0, .1), 0 1px 2px 0 rgba(0, 0, 0, .08), 0 2px 8px 2px rgba(0, 0, 0, .05);
        pointer-events: auto;
    
        position: fixed;
        width: 320px;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%); /* Center the modal horizontally and vertically */
    }
    
    /* Add a media query for mobile devices */
    @media (max-width: 768px) {
        .ant-modal-content {
            width: 90%; /* Make the modal width responsive */
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%); /* Keep the modal centered */
        }
    }
    
    @media (max-width: 480px) {
        .ant-modal-content {
            width: 90%; /* Full width on smaller mobile screens */
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%); /* Center the modal */
        }
    }
    
    
    .ant-modal-close {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 10;
        padding: 0;
        color: rgba(34, 34, 34, .45);
        font-weight: 700;
        line-height: 1;
        text-decoration: none;
        background: transparent;
        border: 0;
        outline: 0;
        cursor: pointer;
        transition: color .3s;
    }
    .ant-modal-close-x{
        display: block;
        width: 56px;
        height: 56px;
        font-size: 18px;
        font-style: normal;
        line-height: 56px;
        text-align: center;
        text-transform: none;
        text-rendering: auto;
    }
    .ant-modal-body {
        padding: 16px 16px 10px 16px;
    }

    .fpcontent {
        .fpSection {
            width: 100%;
            height: 150px;
            .moveElement {
                width: 60px;
                height: 60px;
                position: relative;
                cursor: pointer;
            }

            .info {
                width: 85%;
                position: absolute;
                background-color: #000;
                opacity: 0.7;
                left: 5%;
                top: 20%;
                color: #fff;
                height: 150px;

                img {
                    margin: 10px 0 0 90%;
                    width: 18px;
                    height: auto;
                    cursor: pointer;
                }

                p {
                    padding: 15px;
                }
            }
        }
        .verifySuccess {
            width: 100%;
            height: 40px;
            line-height: 40px;
            background-color: #10a0004a;
            color: #42d200;

            img {
                width: 18px;
                height: auto;
                margin-left: 10px;
                margin-right: 10px;
            }
        }
        .verifyFail {
            width: 100%;
            height: 40px;
            line-height: 40px;
            background-color: rgba(255, 16, 0, 0.20);;
            color: #F11818;
            padding-left: 0px;
            img {
                width: 18px;
                height: auto;
                margin-left: 10px;
                margin-right: 10px;
            }
        }
        .fpFooter {
            margin: 10px 0 0;

            img {
                cursor: pointer;

                &:first-child {
                    margin-right: 15px;
                }
            }
        }
    }
}

